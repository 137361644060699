import * as THREE from 'three';
import px from '../../Images/SpaceCubeMap/px.png';
import nx from '../../Images/SpaceCubeMap/nx.png';
import py from '../../Images/SpaceCubeMap/py.png';
import ny from '../../Images/SpaceCubeMap/ny.png';
import pz from '../../Images/SpaceCubeMap/pz.png';
import nz from '../../Images/SpaceCubeMap/nz.png';

import { BokehDepthShader } from 'three/examples/jsm/shaders/BokehShader2.js'
import { onWindowResize, raycast } from '../GlobalFunctions';
import { animate, setAnimation, updateSpotlightTarget } from './Animate';
import GUI from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { cameraControls, getActiveCamera, mainCameraInitPosition, mainCameraInitRotation, renderer, scene } from './Initial3DSetup';
import { planets } from './Planets';
import { updateImagePosition } from './AddCards';
import gsap from 'gsap';
import { openDoors } from '../../../Components/LobbyContainer';

export let inFocus = false;
export let doorsOpen = false;
let animating = false;

export function setIsAnimating(isAnim) {
    animating = isAnim;
    return animating;
}

export function getIsAnimating() {
    return animating;
}
const urls = [
    px, nx,
    py, ny,
    pz, nz
];
const depthShader = BokehDepthShader;

export function initMainScene() {
    const clock = new THREE.Clock();
    const endPosition = new THREE.Vector3(); // Initialize once
    const startPosition = new THREE.Vector3(); // Initialize once

    const previousMousePosition = new THREE.Vector2();

    const camera = getActiveCamera();
    //const postprocessing = { enabled: true, composer: new EffectComposer(renderer) };
    //const shaderSettings = { rings: 4, samples: 3 };

    const effectController = {
        enabled: true,
        jsDepthCalculation: true,
        shaderFocus: false,

        fstop: 0.05,
        maxblur: 1,

        showFocus: false,
        focalDepth: 2.8,
        manualdof: false,
        vignetting: false,
        depthblur: false,

        threshold: 0.5,
        gain: 2.0,
        bias: 0.5,
        fringe: 0.7,

        focalLength: camera.getFocalLength(),
        noise: true,
        pentagon: false,

        dithering: 0.0001
    };

    //let distance = 100;
    let mousedown = false;
    let prevPlanetSelection = null;
    let planetSelection = null;
    let imgSelection = null;
    let img = null;
    let prevImageSelection = null;
    let prevhoverSelection;
    let imgClicked = false;

    let scrolling = false;

    renderer.autoClear = false;

    setEventListeners();    //setGuis();

    setAnimation(renderer, scene);
    animate();

    function setEventListeners() {

        const canvas = document.querySelector('.mainCanvas');

        canvas.addEventListener('dblclick', (event) => {
            event.preventDefault();          

            if (!doorsOpen) return;
            if (imgClicked || animating) return;
            if (!inFocus) return;
            
            deselectPlanet(prevPlanetSelection);
        })

        canvas.addEventListener('mousemove', (event) => {
            event.preventDefault();

            // don't raycast when moving
            if (animating) return;

            if (mousedown) {
                scrolling = true;
                if (inFocus) {
                    if(imgSelection) {
                        //Return image to original position and rotation without any animation
                       imgSelection.position.copy(imgSelection.userData.initialPos);
                       imgSelection.rotation.copy(imgSelection.userData.initialRot);
                       imgSelection = null;
                    }
                    scrollImages(event);
                }
            } else {
                if (inFocus) {
                    const targets = planetSelection.getObjectByName(planetSelection.userData.imgGroupLabel);
                    if (!targets || targets.children.length === 0) return;                    
                    raycast(event, targets.children, hitTarget => {
                        if (hitTarget) {
                            const image = hitTarget.object;                           
                            if (image !== prevhoverSelection) {
                                hoverImage(image);
                            }
                        }
                    });
                }
            }
        })

        canvas.addEventListener('click', (e) => {

            e.preventDefault();
            //do a check to make sure raycasting is not available if with scrolling mouseup

            if (scrolling) {
                scrolling = false;
                return;
            }

            if (!doorsOpen) {
                raycast(e, scene.children, hitTarget => {
                    if (hitTarget) {
                        const t = hitTarget.object;
                        if (t.name === 'DoorButton') {
                            doorsOpen = true;
                            console.log("hit target", t);
                            openDoors();
                        }
                    }
                })
                return;
            }

            if (animating) return;

            //if focused on one planet and its images, set raycast for the images, else set it to planets
            if (inFocus) {
                console.log('inFocus')
                const target = planetSelection.getObjectByName(planetSelection.userData.imgGroupLabel);
                if (!target || target.children.length === 0) return;
               console.log('target', target);
                raycast(e, target.children, hitTarget => {
                    if (hitTarget) {                        
                        img = hitTarget.object;
                        if (imgSelection) {
                            if (imgSelection === img) {
                                deselectImage();
                            } else {
                                deselectImage(selectImage);
                            }
                        } else  {
                            selectImage(img);
                        }
                    }
                });
            } else {
                raycast(e, planets.children, (hitTarget => {
                    if (hitTarget) {
                        const target = hitTarget.object;
                        planetSelection = target.parent;
                        if (planetSelection.userData.isPlanet)
                            selectPlanet(planetSelection);
                    }
                }))
            }
        });

        canvas.addEventListener('mousedown', (event) => {
            event.preventDefault();
            previousMousePosition.copy(new THREE.Vector2(event.clientX, event.clientY));
            mousedown = true;
        });

        canvas.addEventListener('mouseup', (event) => {
            event.preventDefault();

            mousedown = false;
        });

        window.addEventListener('resize', (e) => onWindowResize);

        cameraControls.addEventListener('change', (e) => {
            if (e.target === null) {
                console.log("change", e);
            }

        })
    }

    function selectImage(img) {
        imgSelection = img;
        imgSelection.userData.initialPos = new THREE.Vector3().copy(img.position);
        imgSelection.userData.initialRot = new THREE.Vector3().copy(img.rotation);
      
        const endPosition = new THREE.Vector3().copy(img.position);
        let camLocal = img.parent.worldToLocal(camera.position.clone());
        endPosition.z = camLocal.z - 7;
        endPosition.x = camLocal.x;
        const endRot = img.rotation.z + (Math.PI * 2);

        gsap.to(img.position, {
            x: endPosition.x,
            z: endPosition.z,
            duration: 1
        });

        gsap.to(img.rotation, {
            z: endRot,
            y: 0,
            duration: 1
        });
    }

    function deselectImage(selectImg) {
        const endRot = imgSelection.rotation.z - (Math.PI * 2);

        gsap.to(imgSelection.position, {
            x: imgSelection.userData.initialPos.x,
            z: imgSelection.userData.initialPos.z,
            duration: 1
        });

        gsap.to(imgSelection.rotation, {
            z: endRot,
            duration: 1
        }).then(() => {
            imgSelection = null;
            if(selectImg)
                selectImg(img);
        });
    }

    function hoverImage(image) {

        image.parent.children.forEach(img => {

            const scale = img === image ? img.userData.scale * 1.2 : img.userData.scale;

            img.scale.set(scale, scale, scale);
        });

        prevhoverSelection = image;
    }

    function scrollImages(event) {

        if (planetSelection === null || planetSelection === undefined) return;

        const deltaMove = { x: event.clientX - previousMousePosition.x, y: event.clientY - previousMousePosition.y };


        const deltaRotation = deltaMove.x * 0.01;
        const group = planetSelection.getObjectByName(planetSelection.userData.imgGroupLabel);

        if (group) {

            group.children.forEach(img => {
                img.userData.angle += deltaRotation;
                updateImagePosition(img, planetSelection);
            });

            previousMousePosition.copy(new THREE.Vector2(event.clientX, event.clientY));
        }
    }

    function selectPlanet(planet, duration = 2) {
        console.log('planet selection is', planet);
        inFocus = true;
        animating = true;

        planetSelection.layers.set(1);

        cameraControls.enabled = false;

        const radius = planet.userData.radius;

        prevPlanetSelection = planet;

        updateSpotlightTarget(planet);
        const imgGroup = planet.getObjectByName(planet.userData.cardGroupLabel);
        const planetLabel = planet.getObjectByName(planet.userData.labelName);
        const planetEndPosition = planet.userData.cameraTarget;

        //Set new position for label to be at the front top of the planet
        const polarAngle = Math.PI / 2.8; // for y axis
        const azimuthalAngle = -24; //for z axis

        const x = planetLabel.position.x;
        const y = radius * Math.cos(polarAngle);
        const z = radius * Math.sin(polarAngle) * Math.sin(azimuthalAngle) + (radius * 0.15);

        const scale = planet.userData.labelScale;

        let startOrientation;
        let targetOrientation;

        planets.traverse(obj => {
            if (obj.isMesh) {
                //show all planets and labels
                if (obj.userData.group === planet.name) {
                    if (obj.userData.isImage) {
                        obj.layers.set(0);
                        gsap.to(obj.material, {
                            opacity: 1,
                            duration: duration
                        });
                       
                    }
                } else {
                    if (!obj.userData.isImage) {
                        gsap.to(obj.material, {
                            opacity: 0,
                            duration: duration
                        })
                    }
                }
            }
        });
        startOrientation = planetLabel.quaternion.clone();
        targetOrientation = camera.quaternion.clone().normalize();
        gsap.to(planetLabel.position, {
            x: x,
            y: y,
            z: z,
            duration: duration,
            onUpdate: function () {
                planetLabel.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
            },
        });

        gsap.to(planetLabel.scale, {
            x: scale,
            y: scale,
            z: scale,
            duration: duration
        });
        startOrientation = camera.quaternion.clone();
        targetOrientation = planet.quaternion.clone().normalize();
        gsap.to(camera.position, {
            x: planetEndPosition.x,
            y: planetEndPosition.y,
            z: planetEndPosition.z,
            onUpdate: function () {
                camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
            },
            duration: duration
        }).then(() => {
            animating = false;

            cameraControls.target = planet.position;
            cameraControls.enabled = false;
            cameraControls.update(clock.getDelta());

            planet.layers.set(0);

        });
    }

    function deselectPlanet(planet, callback, duration = 2) {

        const planetLabel = planet.getObjectByName(planet.userData.labelName);
        console.log('planet label start', planetLabel.position, planetLabel.rotation)
        const imgGroup = planet.getObjectByName(planets.userData.cardGroupLabel);
        console.log('label', planetLabel)


        const lookAtTarget = new THREE.Vector3();
        const cameraEndPosition = new THREE.Vector3().copy(mainCameraInitPosition);
        const cameraEndRotation = new THREE.Vector3().copy(mainCameraInitRotation);
        const labelPos = planet.userData.labelPosition;

        let startOrientation;
        let targetOrientation;

        planets.traverse(obj => {
            if (obj.isMesh) {
                //show all planets and labels
                if (obj.userData.group === planet.name) {
                    if (obj.userData.isImage) {
                        obj.layers.set(1);
                        gsap.to(obj.material, {
                            opacity: 0,
                            duration: duration
                        })
                    }
                } else {
                    if (!obj.userData.isImage) {
                        gsap.to(obj.material, {
                            opacity: 1,
                            duration: duration
                        })
                    }
                }
            }
        });



        planet.parent.traverse(obj => {
            if (obj.userData.isLabel) {

                startOrientation = obj.quaternion.clone();
                targetOrientation = camera.quaternion.clone().normalize();
                if (obj.parent === planet) {
                    gsap.to(obj.position, {
                        x: labelPos.x,
                        y: labelPos.y,
                        z: labelPos.z,
                        duration, duration
                    });
                }

                gsap.to({}, {
                    duration: duration,
                    onUpdate: function () {
                        obj.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
                    }
                });
            }
        })

        gsap.to(planetLabel.scale, {
            x: 1,
            y: 1,
            z: 1,
            duration: duration
        });

        startOrientation = camera.quaternion.clone();
        targetOrientation = new THREE.Quaternion().setFromEuler(mainCameraInitRotation).clone().normalize();
        gsap.to(camera.position, {
            x: cameraEndPosition.x,
            y: cameraEndPosition.y,
            z: cameraEndPosition.z,
            duration: duration,
            onUpdate: function () {
                camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
            },
            onComplete: completeAnim
        });



        function completeAnim() {
            cameraControls.enabled = true;
            cameraControls.target = lookAtTarget;
            inFocus = false;



            planet.layers.set(0);

            if (callback instanceof Function) {
                callback();
            }
        }
    }

}

export function loadCubeMap() {
    const camera = getActiveCamera();
    const materialDepth = new THREE.ShaderMaterial({
        uniforms: depthShader.uniforms,
        vertexShader: depthShader.vertexShader,
        fragmentShader: depthShader.fragmentShader
    })
    materialDepth.uniforms['mNear'].value = camera.near;
    materialDepth.uniforms['mFar'].value = camera.far;

    const textureCube = new THREE.CubeTextureLoader().load(urls);
    scene.background = textureCube;
}



/*  function process() {
     
     camera.updateMatrixWorld();
 
     if (effectController.jsDepthCalculation) {
 
         let targetDistance;
 
         if (planetSelection) {
             targetDistance = planetSelection.userData.distance;
         }
         else {
             targetDistance = 1000;
         }
 
         distance += (targetDistance - distance) * 0.03;
 
         const sdistance = smoothstep(camera.near, camera.far, distance);
 
         const ldistance = linearize(1 - sdistance);
 
         postprocessing.bokeh_uniforms['focalDepth'].value = ldistance;
 
         effectController['focalDepth'] = ldistance;
 
     }
 
     if (postprocessing.enabled) {
 
         renderer.clear();
 
         // render scene into texture
 
         renderer.setRenderTarget(postprocessing.rtTextureColor);
         renderer.clear();
         render();
 
         // render depth into texture
 
         scene.overrideMaterial = materialDepth;
         renderer.setRenderTarget(postprocessing.rtTextureDepth);
         renderer.clear();
         render();
         scene.overrideMaterial = null;
 
         // render bokeh composite
 
         renderer.setRenderTarget(null);
 
         render(() => renderer.render(postprocessing.scene, postprocessing.camera));
 
     } else {
 
         scene.overrideMaterial = null;
 
         renderer.setRenderTarget(null);
         renderer.clear();
         render();
 
     }
 
     function linearize(depth) {
 
         const zfar = camera.far;
         const znear = camera.near;
         return - zfar * znear / (depth * (zfar - znear) - zfar);
 
     }
 
     function smoothstep(near, far, depth) {
 
         const x = saturate((depth - near) / (far - near));
         return x * x * (3 - 2 * x);
 
     }
 
     function saturate(x) {
 
         return Math.max(0, Math.min(1, x));
 
     }
 
 } */

/*function setGuis() {
 let camGui = new GUI();
camGui.add(camera.position, 'x', -500, 500, 0.01);
camGui.add(camera.position, 'y', -500, 500, 0.01);
camGui.add(camera.position, 'z', -500, 500, 0.01);
camGui.add(camera.rotation, 'x', -Math.PI, Math.PI, 0.01);
camGui.add(camera.rotation, 'y', -Math.PI, Math.PI, 0.01);
camGui.add(camera.rotation, 'z', -Math.PI, Math.PI, 0.01);
 
camGui.open(); */

/*  const gui = new GUI();

  gui.add(effectController, 'enabled').onChange(matChanger);
  gui.add(effectController, 'jsDepthCalculation').onChange(matChanger);
  gui.add(effectController, 'shaderFocus').onChange(matChanger);
  gui.add(effectController, 'focalDepth', 0.0, 22.0).listen().onChange(matChanger);

  gui.add(effectController, 'fstop', 0.05, 50, 0.001).onChange(matChanger);
  gui.add(effectController, 'maxblur', 0.0, 5.0, 0.025).onChange(matChanger);

  gui.add(effectController, 'showFocus').onChange(matChanger);
  gui.add(effectController, 'manualdof').onChange(matChanger);
  gui.add(effectController, 'vignetting').onChange(matChanger);

  gui.add(effectController, 'depthblur').onChange(matChanger);

  gui.add(effectController, 'threshold', 0, 1, 0.001).onChange(matChanger);
  gui.add(effectController, 'gain', 0, 100, 0.001).onChange(matChanger);
  gui.add(effectController, 'bias', 0, 3, 0.001).onChange(matChanger);
  gui.add(effectController, 'fringe', 0, 5, 0.001).onChange(matChanger);

  gui.add(effectController, 'focalLength', 16, 80, 0.001).onChange(matChanger);

  gui.add(effectController, 'noise').onChange(matChanger);

  gui.add(effectController, 'dithering', 0, 0.001, 0.0001).onChange(matChanger);

  gui.add(effectController, 'pentagon').onChange(matChanger);

  gui.add(shaderSettings, 'rings', 1, 8).step(1).onChange(shaderUpdate);
  gui.add(shaderSettings, 'samples', 1, 13).step(1).onChange(shaderUpdate);

  gui.close();

  matChanger();

  function shaderUpdate() {

      postprocessing.materialBokeh.defines.RINGS = shaderSettings.rings;
      postprocessing.materialBokeh.defines.SAMPLES = shaderSettings.samples;
      postprocessing.materialBokeh.needsUpdate = true;

  }

  function matChanger() {
      for (const e in effectController) {

          if (e in postprocessing.bokeh_uniforms) {

              postprocessing.bokeh_uniforms[e].value = effectController[e];

          }

      }

      postprocessing.enabled = effectController.enabled;
      postprocessing.bokeh_uniforms['znear'].value = camera.near;
      postprocessing.bokeh_uniforms['zfar'].value = camera.far;
      camera.setFocalLength(effectController.focalLength);
  };
}
*/

/* function postProcessOnResize(e) {
    
    const elWidth = el.clientWidth;
    const elHeight = el.clientHeight;

    camera.aspect = elWidth / elHeight;
    camera.updateProjectionMatrix();

    postprocessing.rtTextureDepth.setSize(elWidth, elHeight);
    postprocessing.rtTextureColor.setSize(elWidth, elHeight);

    postprocessing.bokeh_uniforms['textureWidth'].value = elWidth;
    postprocessing.bokeh_uniforms['textureHeight'].value = elHeight;

    renderer.setSize(elWidth, elHeight);
    postprocessing.composer.setSize(elWidth, elHeight);

} */

/*  function initPostProcesses() {
     const el = document.querySelector('mainCanvas');
     const elWidth = el.clientWidth;
     const elHeight = el.clientHeight;
 
     postprocessing.scene = new THREE.Scene();
 
     postprocessing.camera = new THREE.OrthographicCamera(window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, - 10000, 10000);
     postprocessing.camera.position.z = 100;
 
     postprocessing.scene.add(postprocessing.camera);
 
     postprocessing.rtTextureDepth = new THREE.WebGLRenderTarget(elWidth, elHeight, { type: THREE.HalfFloatType });
     postprocessing.rtTextureColor = new THREE.WebGLRenderTarget(elWidth, elHeight, { type: THREE.HalfFloatType });
 
     const bokeh_shader = BokehShader;
 
     postprocessing.bokeh_uniforms = THREE.UniformsUtils.clone(bokeh_shader.uniforms);
 
     postprocessing.bokeh_uniforms['tColor'].value = postprocessing.rtTextureColor.texture;
     postprocessing.bokeh_uniforms['tDepth'].value = postprocessing.rtTextureDepth.texture;
     postprocessing.bokeh_uniforms['textureWidth'].value = elWidth;
     postprocessing.bokeh_uniforms['textureHeight'].value = elHeight;
 
     postprocessing.materialBokeh = new THREE.ShaderMaterial({
 
         uniforms: postprocessing.bokeh_uniforms,
         vertexShader: bokeh_shader.vertexShader,
         fragmentShader: bokeh_shader.fragmentShader,
         defines: {
             RINGS: shaderSettings.rings,
             SAMPLES: shaderSettings.samples
         }
 
     });
 
     postprocessing.quad = new THREE.Mesh(new THREE.PlaneGeometry(window.innerWidth, window.innerHeight), postprocessing.materialBokeh);
     postprocessing.quad.position.z = - 500;
     postprocessing.scene.add(postprocessing.quad);
 } */
