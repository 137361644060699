import * as THREE from 'three';
import earth from '../../Images/Planets/2k_earth_daymap.jpg';
import mercury from '../../Images/Planets/2k_mercury.jpg';
import mars from '../../Images/Planets/2k_mars.jpg';
import venus from '../../Images/Planets/2k_venus_surface.jpg';
import jupiter from '../../Images/Planets/2k_jupiter.jpg';
import saturn from '../../Images/Planets/2k_saturn.jpg';
import saturnRing from '../../Images/Planets/2k_saturn_ring_alpha.png'
import uranus from '../../Images/Planets/2k_uranus.jpg';
import neptune from '../../Images/Planets/2k_neptune.jpg';
import Sun from '../../Images/Planets/2k_sun.jpg';
//import Asteroid from '../../GLTF/Astreoid-1.glb';
import { getActiveCamera, renderer, rig, scene } from './Initial3DSetup';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import Begok from 'three/examples/fonts/droid/droid_sans_regular.typeface.json';
import { cardGroups as imgGroups, fetchImageURLs, setPlanetImages } from './AddCards';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';


export const planets = new THREE.Group();
export const planetLabels = new THREE.Group();

const TextParam = {
    font: null,
    depth: 2,
    size: 3,
    curveSegments: 4,
    bevelThickness: 0.1,
    bevelSize: 0.1,
    bevelEnabled: true
}
export const texts = [];

export function loadCelestialBodies() {

    planets.name = 'Planets';
    planetLabels.name = 'PlanetLabels'
    imgGroups.name = 'ImgGroups';

    const sunRad = 14.5;
    const radiusOffset = 0.007;

    // Planets data
    const planetInfo = [
        { name: 'Sun', texture: Sun, distance: 0, size: 14.5, planetLabel: 'Caricatures', scale: 1, radiusOffset: 0.35 },
        { name: 'Mercury', texture: mercury, distance: 5.8 + sunRad, size: 0.196, planetLabel: 'Comics', scale: 0.00357 * 4, radiusOffset: 0 },
        { name: 'Venus', texture: venus, distance: 10.8 + sunRad, size: 0.484, planetLabel: 'Nature', scale: 0.00869 * 4, radiusOffset: 0 },
        { name: 'Earth', texture: earth, distance: 15 + sunRad, size: 0.5104, planetLabel: 'Portraits', scale: 0.00917 * 4, radiusOffset: 0 },
        { name: 'Mars', texture: mars, distance: 22.5 + sunRad, size: 0.276, planetLabel: 'AboutMe', scale: 0.00489 * 4, radiusOffset: 0 },
        { name: 'Jupiter', texture: jupiter, distance: 75 + sunRad, size: 6.4, planetLabel: 'Web3D_VR', scale: 0.1 * 4, radiusOffset: 0.05 },
        { name: 'Saturn', texture: saturn, distance: 140 + sunRad, size: 4.72, planetLabel: 'Under Construction', scale: 0.08663 * 4, radiusOffset: 0.05 },
        { name: 'Uranus', texture: uranus, distance: 290 + sunRad, size: 2.4, planetLabel: 'Under Construction', scale: 0.03663 * 4, radiusOffset: 0 },
        { name: 'Neptune', texture: neptune, distance: 450 + sunRad, size: 2, planetLabel: 'Under Construction', scale: 0.03591 * 4, radiusOffset: 0 }
    ];

    const angleOffset = 360 / planetInfo.length;
    let angle = 1;

    loadAllPlanets();

    function loadAllPlanets() {
        const texLoader = new THREE.TextureLoader();
        const fontLoader = new FontLoader();
        TextParam.font = fontLoader.parse(Begok);

        const sunLight = new THREE.PointLight(0x404040, 1000, 500, 0.8); // bright from the center of the sun
        sunLight.name = 'Sunlight';
        sunLight.position.set(0, 0, 0);
        sunLight.castShadow = true;

        scene.add(sunLight);
        scene.add(planets);
        fetchImageURLs();
        // Create planets
        planetInfo.forEach(p => {

            const planet = addPlanet(p);

            setPlanetImages(planet);

            planets.add(planet);

        });


        function addPlanet(p) {
            const planet = new THREE.Group();
            planet.name = p.name;

            const geometry = new THREE.SphereGeometry(p.size, 64, 64);
            geometry.computeBoundingSphere();

            const texture = texLoader.load(p.texture);

            const material = p.name === 'Sun' ? new THREE.MeshBasicMaterial({ map: texture, emissive: 0x2222ff }) :
                new THREE.MeshStandardMaterial({ map: texture });

            const planet_mesh = new THREE.Mesh(geometry, material);

            planet_mesh.receiveShadow = p.name === 'Sun' ? false : true;

            angle *= angleOffset;
            planet.position.x = p.distance * Math.cos(angle);
            planet.position.z = p.distance * Math.sin(angle);
            planet.userData.spotlightIntensity = 4.959 * p.size;

            const planetLabel = createText(TextParam, p.planetLabel, p.size);
            planetLabel.userData.group = planet.name;
            planetLabel.userData.isLabel = true;

            planet.userData.labelScale = p.scale;
            planet.userData.labelPosition = new THREE.Vector3().copy(planetLabel.position);
            planet.userData.radiusOffset = p.size * radiusOffset - p.radiusOffset;
            planet.userData.imgGroupLabel = p.planetLabel;
            planet.userData.focus = false;
        

            planet_mesh.name = p.name + '_mesh';
            planet_mesh.userData.group = planet.name;

            planet.userData.radius = p.size;
            planet.userData.isFocused = false;
            planet.userData.isPlanet = true;
           
            //Add Satrun's Ring
            if (p.name == 'Saturn') {
                addSaturnRing(planet_mesh);
                planet_mesh.rotateX(35);
            }

            planet.add(planet_mesh);

            planet.add(planetLabel);

            setPlanetPositions(planet, getActiveCamera());

            return planet;
        }

        function addSaturnRing(mesh) {
            //ADD RING
            const ringGeometry = new THREE.CylinderGeometry(5, 10, 0.01, 128, 1, true);  // Inner radius, outer radius, and segments

            const ringTexture = texLoader.load(saturnRing);

            ringTexture.rotation = Math.PI / 2;

            const ringMaterial = new THREE.MeshBasicMaterial({ map: ringTexture, side: THREE.DoubleSide });

            ringMaterial.transparent = true;
            ringMaterial.opacity = 0.85;
            ringMaterial.shadowSide = THREE.DoubleSide;

            const ring = new THREE.Mesh(ringGeometry, ringMaterial);
            ring.name = 'SaturnRing';
            ring.receiveShadow = true;

            mesh.add(ring);

            //Asteroids if needed
            /*  const debriLoader = new GLTFLoader();
             debriLoader.load(Asteroid, (obj) => {                               
                 const asteroid = obj.scene.children[0].clone();
                 const numberOfAsteroids = 2500;
                 asteroid.material.castShadow = true;
                 asteroid.material.receiveShadow = true;
                 //asteroid.material.shadowSide = THREE.DoubleSide;
                 asteroid.geometry.scale(0.05, 0.05, 0.05);
                 const asteroidGroup = new THREE.InstancedMesh(
                     asteroid.geometry,
                     asteroid.material,
                     numberOfAsteroids
                 );
                 asteroidGroup.name = 'asteroids';
               
                 scene.add(asteroidGroup);
               
                 
 
                 for (let i = 0; i < numberOfAsteroids; i++) {                   
                     const theta = 2 * Math.PI * Math.random(); // Random angle
                     const distance = 16 + 8 * Math.random(); // Random distance from the center
                     const x = distance * Math.cos(theta);
                     const z = distance * Math.sin(theta);
                     const y = Math.random() * 0.2; // Small random height to give some thickness
 
                     const dummy = new THREE.Object3D();
                     const scale = r();
                     dummy.position.set(x, y, z);
                     dummy.scale.set(scale, scale, scale);
                     dummy.rotation.set(r(5 / Math.PI, 350 / Math.PI), r(5 / Math.PI, 350 / Math.PI), r(5 / Math.PI, 350 / Math.PI));
 
                     dummy.matrix.setPosition(dummy.position);
                     dummy.updateMatrix();
 
                     asteroidGroup.setMatrixAt(i, dummy.matrix);
 
                 }
                 asteroidGroup.position.copy(mesh.position);
                 asteroidGroup.position.y -= 0.2;
 
                 asteroidGroup.instanceMatrix.needsUpdate = true;
                 console.log(asteroidGroup)
                      
             }); */

            /* function r(min, max) {
                const random = min? min + Math.random() * (max - min): Math.random();

                return random
            } */
        }
    }
}

function setPlanetPositions(planet, camera) {
    const fovInRadians = (camera.fov * Math.PI) / 180;
    const distanceFromCenter = planet.userData.radius / Math.sin(fovInRadians / 2);
    const direction = new THREE.Vector3(0, 0, 1).normalize();

    const endPosition = new THREE.Vector3().copy(planet.position).add(direction.multiplyScalar(distanceFromCenter));

    planet.userData.cameraTarget = endPosition;
}

function createText(param, text, radius) {

    const textGeo = new TextGeometry(text, {
        font: param.font,
        size: param.size,
        height: param.depth,
        hover: param.hover,
        curveSegments: param.curveSegments,
        bevelThickness: param.bevelThickness,
        bevelSize: param.bevelSize,
        bevelEnabled: param.bevelEnabled
    });
    const offsetY = radius + 2;
    centerGeometry(textGeo);
    //textGeo.translate(0, offsetY, 0);

    const materials = [
        new THREE.MeshPhongMaterial({ color: 0x8dbefc, flatShading: true, transparent: true }), // front
        new THREE.MeshPhongMaterial({ color: 0x8dbefc, transparent: true }) // side
    ];

    const textMesh = new THREE.Mesh(textGeo, materials);
    textMesh.receiveShadow = true;
    textMesh.name = text + '_mesh'
    textMesh.layers.set(1);
    textMesh.position.setY(radius + 2);

    return textMesh;

}

function centerGeometry(textGeo) {

    textGeo.computeBoundingBox();

    const offsetX = (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x) / 2;
    const offsetY = (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y) / 2;
    const offsetZ = (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z) / 2;

    textGeo.translate(-offsetX, -offsetY, -offsetZ);
}



