import * as THREE from 'three';
import { percentage, setPercentage } from '../GlobalFunctions';

const FOLDERS = ['Comics', 'Caricatures', 'Portraits', 'Nature', 'Web3D_VR'];
let imgFolderURLs;
export const cardGroups = new THREE.Group();

export function fetchImageURLs() {
    imgFolderURLs = FOLDERS.reduce((acc, folder) => {
        let context;
        switch (folder) {
            case 'Comics': context = require.context('../../Images/ImgGroups/Comics', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Caricatures': context = require.context('../../Images/ImgGroups/Caricatures', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Portraits': context = require.context('../../Images/ImgGroups/Portraits', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Nature': context = require.context('../../Images/ImgGroups/Nature', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Web3D_VR': context = require.context('../../Images/ImgGroups/Web3D_VR', false, /\.(png|jpe?g|svg)$/);
                break;
        }
        acc[folder] = importAll(context);
        return acc;
    }, {});
}

function importAll(r) {
    let imgs = {};
    r.keys().map((item, index) => {
        let x = item.replace('./', '');
        x = x.replace(/\.(png|jpe?g|svg)$/, '')

        imgs[x] = r(item);
    });

    return imgs;
}

export function setPlanetImages(planet) {

    planet.traverse(child => {
        //get the label mesh to create new img group
        if (child.isMesh && child.geometry.type === 'TextGeometry') {
            const imgGroup = new THREE.Group();
            imgGroup.userData.isImgGroup = true;
            planet.userData.labelName = child.name;
            imgGroup.name = child.name.split('_')[0];
            imgGroup.position.y -= planet.userData.radius / 4;
            imgGroup.rotation.set(0, 0, 0);
            planet.add(imgGroup);

            setImages(imgGroup, planet.userData.radius);
        }
    });
}

function setImages(imgGroup, radius) {
    const images = imgFolderURLs[imgGroup.name];
    if (images === undefined) return;

    const manager = new THREE.LoadingManager();
    const textureLoader = new THREE.TextureLoader(manager);
    let perc = 0;

    const numberOfImages = Object.keys(images).length;

    manager.onLoad = function () {
        console.log('All items loaded.', perc);
        positionImages(imgGroup, radius);
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        const el = document.querySelector('#loading');
        perc = Math.trunc((itemsLoaded / numberOfImages * 100) / 2);
        const prog = 'Loading Image Textures... ' + perc + '%.';
        el.innerText = prog;
        setPercentage(perc);
    };

   
     manager.onLoad = function () {       
        positionImages(imgGroup, radius);
    };
    /*
    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onError = function (url) {
        console.log('There was an error loading ' + url);
    };*/
    
    if (numberOfImages === 0) return;

    
    // Load all textures   
    Object.keys(images).map((img) => {
        textureLoader.load(images[img], (texture) => {
            createImages(texture, imgGroup, radius, img);
        });
    })
}

function createImages(texture, imgGroup, radius, img) {
    const height = radius * 0.75;
    const aspectRatio = texture.image.width / texture.image.height;
    const width = height * aspectRatio;
    const cardGeometry = new THREE.PlaneGeometry(width, height);

    //Not sure why rotation adjustment is needed???            
    cardGeometry.rotateY(Math.PI);
    //////////////////////////////////////////////////

    const cardMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(cardGeometry, cardMaterial);
    mesh.name = img;
    mesh.material.transparent = true;
    mesh.material.opacity = 0;
    mesh.userData.isImage = true;
    mesh.userData.group = imgGroup.parent.name;
    mesh.layers.set(1);
    imgGroup.add(mesh);
}

function positionImages(imgGroup, radius) {
    const planet = imgGroup.parent;
    const numImages = imgGroup.children.length;
    imgGroup.position.set(0, 0, radius * 0.1);
    imgGroup.rotateY(0);

    imgGroup.children.forEach((img, i) => {
        const angle = (i / numImages) * Math.PI * 2;
        const majorRad = radius * 2;
        const minorRad = radius / 1.5;
        img.userData.rad = { major: majorRad, minor: minorRad };
        img.userData.angle = angle;

        updateImagePosition(img, planet);

    });
}

export function updateImagePosition(img, planet) {
    const x = img.userData.rad.minor * Math.sin(img.userData.angle);
    const z = img.userData.rad.major * Math.cos(img.userData.angle);
    img.position.set(
        x,
        0,
        z
    );
    const scale = .3;
    img.scale.set(scale, scale, scale);
    img.userData.scale = scale;
    img.lookAt(planet.position);
}

