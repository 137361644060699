import '../CSS/App.css'
import * as THREE from 'three';


import { getActiveCamera, renderer, scene } from './THREEJS/Initial3DSetup';
import { render } from './THREEJS/Animate'

export let percentage = 0;

export function setPercentage(perc) {
    percentage = perc;    
}

export function getPercentage() {   
    return percentage;
}

export function onWindowResize(e, callback) {
    const rig = scene.getObjectByName('Camera Rig');
    if (callback) {
        callback(e);
    }
    else {
        const el = document.querySelector('mainCanvas');
        rig.children.forEach(camera => {
            if (camera.isCamera) {
                camera.aspect = el.clientWidth / el.clientHeight;
                camera.updateProjectionMatrix();
            }
        });

        renderer.setSize(el.clientWidth, el.clientHeight);
    }

    render();

}

export function raycast(event, targets, callback) {    
    const mouse = getNormalizedMouseCoords(event, renderer);
    const camera = getActiveCamera();
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    raycaster.layers.set(0);
    raycaster.far = 100000;
    raycaster.near = 1;
    //debugRaycaster(raycaster);
    const intersects = raycaster.intersectObjects(targets, true);    
    let result = null;    
    if(intersects.length > 0) {
        result = intersects[0];       
    }
       

    callback(result);

    function getNormalizedMouseCoords(event) {
        const mouse = new THREE.Vector2();
        const rect = renderer.domElement.getBoundingClientRect();
        /*
        //must use offsetX and Y if not using window as renderer.domElement, otherwise it will be inacurate
        */
        mouse.x = ((event.offsetX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.offsetY- rect.top) / rect.height) * 2 + 1;
        return mouse;
    }
}

export function debugRaycaster(raycaster) {
    const geometry = new THREE.SphereGeometry(1);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(raycaster.ray.origin);
    scene.add(sphere);

    // Optional: show ray direction
    const arrow = new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 5, 0xff0000, 0.1, 0.1);
    scene.add(arrow);
}

/*export function loadingManager(manager, params) {
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
        console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onLoad = function () {
        console.log('All items loaded.');

    };
    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onError = function (url) {
        console.log('There was an error loading ' + url);
    };
}*/

