import * as THREE from 'three';
import { cameraControls, getActiveCamera, getSpotlight, mainCameraInitPosition, mainCameraInitRotation, scene } from '../Assets/JS/THREEJS/Initial3DSetup';
import { planets } from '../Assets/JS/THREEJS/Planets';
//import { loadingManager } from '../Assets/JS/GlobalFunctions';
import gsap from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import gateGLB from '../Assets/GLTF/Gate_Animated.glb';
import { updateSpotlightTarget } from '../Assets/JS/THREEJS/Animate';
import { loadCubeMap, setIsAnimating } from '../Assets/JS/THREEJS/MainScene';
import { getPercentage, percentage } from '../Assets/JS/GlobalFunctions';


export const doors = new THREE.Group();

const manager = new THREE.LoadingManager();
const clock = new THREE.Clock();

let gate = new THREE.Object3D();

let target
export let timeline;

export function loadLobbyContainer() {

    const il = new GLTFLoader(manager);

    il.load(gateGLB, (obj => {

        gate = obj.scene.children[0];

        gate.position.copy(getActiveCamera().position);
        gate.position.z -= 2;

        const targetGeo = new THREE.BoxGeometry(1, 1, 0.1, 1, 1, 1);
        const targetMat = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
        target = new THREE.Mesh(targetGeo, targetMat);
        target.name = 'doorTarget';
        gate.position.z -= 3;
        gate.name = 'gate';
        target.position.copy(gate.position);
        target.position.z += 0.15;
        target.position.y -= 0.125;
        target.layers.set(1);

        scene.add(target);
        scene.add(gate);

        const o = new THREE.Object3D();
        getActiveCamera().add(o);
        o.position.set(0, 0, -1);
        o.userData.spotlightIntensity = 10;
        addPointLights(gate);

        //getActiveCamera().lookAt(target);
        updateSpotlightTarget(o);

        const t = new THREE.Vector3().copy(getActiveCamera().position);
        t.z -= 0.1;
        cameraControls.target = t;
        cameraControls.screenSpacePanning = false; // Disable screen-space panning        

        loadCubeMap();

    }));

    let perc;
    

    manager.onLoad = function () {
        const el = document.querySelector('#loading');
        el.classList.add('hidden');       
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        perc = getPercentage();
        const el = document.querySelector('#loading');
        const newperc = perc + Math.trunc((itemsLoaded / itemsTotal * 100) / 2);
        const prog = 'Loading 3D Models... ' + newperc + '%.';       
        el.innerText = prog;
    };
}

export function openDoors() {

    const camera = getActiveCamera();

    const startOrientation = camera.quaternion.clone();
    const targetOrientation = target.quaternion.clone().normalize();
    let doorOpenLeft, doorOpenRight, move;
    setIsAnimating(true);

    function launchCamera() {
        gsap.to(camera.position, {
            z: mainCameraInitPosition.z,
            x: mainCameraInitPosition.x,
            duration: 4,
            ease: 'power4.inOut'
        }).then(closeDoors);

        gsap.to(camera.rotation, {
            y: mainCameraInitRotation.y,
            delay: 0.25,
            duration: 3.75,
            ease: 'power4.out',
            onUpdate: () => {
                planets.traverse(obj => {
                    if (obj.isPlanetLabel) {
                        startOrientation = obj.quaternion.clone();
                        targetOrientation = camera.quaternion.clone();
                        obj.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
                    }
                });
            }
        });
    }

    const lookAtDoor = gsap.to({}, {
        duration: 1,
        onUpdate: function () {
            camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
        }
    });


    timeline = gsap.timeline();


    gate.children.forEach(door => {
        if (door.name === 'Door_left') {
            move = door.position.x - 2;
            openDoor(door, move, true);
        } else if (door.name === 'Door_right') {
            move = door.position.x + 2;
            openDoor(door, move, false);
        }
    });

    timeline.add(lookAtDoor).add(doorOpenLeft, '-=0.25').add(doorOpenRight, '-=2').add(launchCamera);

    function openDoor(door, move, left) {
        if (left) {
            doorOpenLeft = gsap.to(door.position, {
                x: move,
                duration: 2
            });
        } else {
            doorOpenRight = gsap.to(door.position, {
                x: move,
                duration: 2
            });
        }
    }

    timeline.play();
}



function closeDoors() {
    setIsAnimating(false);
    scene.remove(gate);
    cameraControls.target = new THREE.Vector3();
    cameraControls.enabled = true;

    const spotlight = getSpotlight();
    spotlight.target = target;
    spotlight.distance = 100;
    spotlight.intensity = 6;
    spotlight.decay = 1;
    spotlight.penumbra = 1;
    spotlight.focus = 1;
    spotlight.angle = 0.25;

    cameraControls.update(clock.getDelta());
}

function addPointLights(gate) {
    const pointlights = [new THREE.PointLight(), new THREE.PointLight(), new THREE.PointLight(), new THREE.PointLight()];

    pointlights.forEach((light, i) => {
        light.position.copy(gate.position);
        light.position.y += 1.15;
        light.position.z += 0.25;
        if (i % 2 === 0) {
            light.position.x += 0.85;
        } else {
            light.position.x -= 0.85;
        }

        if (i > 2) {
            light.position.z += 0.25;
        } else {
            light.position.z -= 0.25;
        }

        light.intensity = 0.25;
        light.color = new THREE.Color('green')
        scene.add(light);
    })

}

