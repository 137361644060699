import * as THREE from 'three';
import { planets } from './Planets';
import { getActiveCamera, getSpotlight } from './Initial3DSetup';
import { getIsAnimating, inFocus } from './MainScene';

let renderer;
let scene;


export function setAnimation(rend, scn) {
    renderer = rend;
    scene = scn;
}

export function animate() {

    requestAnimationFrame(animate);
    
    if (planets.children.length > 0) {

        planets.children.forEach(planetGroup => {

            ////Planets Spin/////////
            const planet = planetGroup.children[0];
            if (!planet) return;

            if (planet.name === 'Sun_mesh') planet.rotateY(0.0005)
            else planet.rotateY(0.005); // spin on its axis 

            /////Titles Follow the camera, but only when no planets are selected//////////////////
            if (inFocus || getIsAnimating()) return;

            const planetLabel = planetGroup.getObjectByName(planetGroup.userData.labelName);
            if (planetLabel) {
                const dir = new THREE.Vector3();
                dir.subVectors(getActiveCamera().position, planetLabel.position).normalize();

                const angle = Math.atan2(dir.x, dir.z);

                planetLabel.rotation.y = angle;
            }
        });
    }

    render();
};

export function render() {

    renderer.render(scene, getActiveCamera());
};

export function updateSpotlightTarget(target) {
    const spot = getSpotlight();

    spot.intensity = target.userData.spotlightIntensity;

    spot.target = target;
}
