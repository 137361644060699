import React, { Component } from 'react';
//import * as BS from 'react-bootstrap';
import './Assets/CSS/App.css';

import { loadCelestialBodies } from './Assets/JS/THREEJS/Planets';
import { loadMainScene, scene } from './Assets/JS/THREEJS/Initial3DSetup';
import { initMainScene } from './Assets/JS/THREEJS/MainScene';
import samsonart from './Assets/Images/Planets/samsonart.png';
import { loadLobbyContainer } from './Components/LobbyContainer';
export default class App extends Component {

  componentDidMount() {
    // Scene setup
    loadMainScene();    
    loadCelestialBodies();    
    initMainScene();
    loadLobbyContainer();
    console.log("my scene", scene);
  }

  render() {
    return (
      <div className="appContainer">
        <h4 id='loading'>Loading...</h4>
        <div className='mainCanvas' >
          <div className='mainTitle'>
            <img src={samsonart} id='samsonart' />
          </div>
        </div>
      </div>
    );
  }
}