import * as THREE from 'three';
import { onWindowResize } from '../GlobalFunctions';
import { GUI } from 'dat.gui';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export const mainCameraInitPosition = new THREE.Vector3(107.25, 0, 96.5);
export const mainCameraInitRotation = new THREE.Euler(0, 0.95, 0);
const spotlight = new THREE.SpotLight();


export const scene = new THREE.Scene();
export const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
export let h;
export const rig = new THREE.Group();
export let cameraControls;

let canvasEl;
let activeCamera;
let aspect = null;
let setup = {}
const camera = new THREE.PerspectiveCamera();

export function loadMainScene() {
    scene.name = 'mainScene';
    scene.fog = null;
    canvasEl = document.querySelector('.mainCanvas');
    aspect = canvasEl.clientWidth / canvasEl.clientHeight;

    renderer.setSize(canvasEl.clientWidth, canvasEl.clientHeight);
    renderer.encoding = true;
    renderer.outputEncoding = true;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    canvasEl.appendChild(renderer.domElement);

    //ADD CAMERAS AND LIGHTS
    loadCameras();

    const axesHelper = new THREE.AxesHelper(1000);
    scene.add(axesHelper);

    //setGui();
    setup = { scene: scene, renderer: renderer, el: canvasEl };

    setActiveCamera(camera);
}

function loadCameras() {
    rig.name = 'Camera Rig'
    rig.position.set(0, 0, 0);

    camera.copy(new THREE.PerspectiveCamera(35, aspect, 0.1, 5000));
    camera.name = 'mainCamera';
    camera.position.copy(new THREE.Vector3(0, 0, 10000));
    camera.layers.enable(1);

    rig.add(camera);

    scene.add(rig);

    setActiveCamera(camera);

    setCameraControls();

    console.log("my rig ", rig);
    loadLighting(camera);
}

function loadLighting() {
    const ambient = new THREE.HemisphereLight(0xffffff, 0x8d8d8d, 0.1);
    scene.add(ambient);

    spotlight.distance = 100;
    spotlight.intensity = 8;
    spotlight.decay = 1;
    spotlight.penumbra = 1;
    spotlight.focus = 1;
    spotlight.angle = 0.5;
    spotlight.name = 'cameraSpotLight';
    spotlight.castShadow = true;
    spotlight.position.y -= 1;

    camera.add(spotlight);
}

export function setActiveCamera(cam = null) {
    ///activeCamera.visible = false;
    if (cam) {
        activeCamera = cam;
    } else {

        if (getActiveCamera().name === 'mainCamera') {
            activeCamera = rig.getObjectByName('newCamera');
            cameraControls.enabled = false;
        }
        else {
            console.log("new Camera to be added");
        }
    }
}

export function setCameraControls() {
    // OrbitControls
    cameraControls = new OrbitControls(getActiveCamera(), renderer.domElement);
    cameraControls.dragToLook = true;
    cameraControls.movementSpeed = 100;
    cameraControls.rollSpeed = 0.3;
    cameraControls.enabled = true;
}


export function getActiveCamera() {
    return activeCamera;
}

export function getSpotlight() {
    return spotlight;
}

export function setSpotlightParams(params) {
    if (params) {
        Object.keys(params).map((p) => {
            spotlight.intensity = p === 'intensity' ? params[p] : spotlight.intensity;
            spotlight.distance = p === 'distance' ? params[p] : spotlight.distance;
            spotlight.angle = p === 'angle' ? params[p] : spotlight.angle;
        })
    }
}

// GUI
/* function setGui() {
    const gui = new GUI();

    const params = {
        color: spotlight.color.getHex(),
        intensity: spotlight.intensity,
        distance: spotlight.distance,
        angle: spotlight.angle,
        penumbra: spotlight.penumbra,
        decay: spotlight.decay,
        focus: spotlight.shadow.focus,
        shadows: true        
    };

    gui.addColor(params, 'color').onChange(function (val) {

        spotlight.color.setHex(val);

    });

    gui.add(params, 'intensity', 0, 500).onChange(function (val) {

        spotlight.intensity = val;

    });


    gui.add(params, 'distance', 0, 200).onChange(function (val) {

        spotlight.distance = val;

    });

    gui.add(params, 'angle', 0, Math.PI / 3).onChange(function (val) {

        spotlight.angle = val;

    });

    gui.add(params, 'penumbra', 0, 1).onChange(function (val) {

        spotlight.penumbra = val;

    });

    gui.add(params, 'decay', 1, 2).onChange(function (val) {

        spotlight.decay = val;

    });

    gui.add(params, 'focus', 0, 1).onChange(function (val) {

        spotlight.shadow.focus = val;

    });


    gui.add(params, 'shadows').onChange(function (val) {
        renderer.shadowMap.enabled = val;
        scene.traverse(function (child) {
            if (child.material) {
                child.material.needsUpdate = true;
            }
        });
    });

    gui.open();
} */

